<template>
  <Dialog
    :close-on-escape="false"
    :show-dialog="showDialog"
  >
    <Card
      class="tw-w-full lg:tw-w-96"
      :has-body-padding="true"
    >
      <template #body>
        <p class="text-darkest-color">
          {{ message }}
        </p>
        <footer class="tw-flex tw-flex-row-reverse tw-px-2 tw-py-1 tw-mt-4">
          <Button
            class="tw-ml-1"
            :type="confirmButtonType"
            value="confirm"
            @click="() => handleClose(true)"
          >
            {{ confirmButtonText }}
          </Button>
          <Button
            autofocus
            class="tw-mr-1"
            :type="cancelButtonType"
            value="cancel"
            @click="() => handleClose(false)"
          >
            {{ cancelButtonText }}
          </Button>
        </footer>
      </template>
    </Card>
  </Dialog>
</template>

<script>
import { ButtonTypes } from '@@/components/Common/Button.vue';

export default {
  name: 'ConfirmDialog',

  props: {
    message: {
      type: String,
      default: 'Are you sure?',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    cancelButtonType: {
      type: String,
      default: ButtonTypes.help,
      validator(type) {
        return Object.values(ButtonTypes).includes(type);
      },
    },
    confirmButtonText: {
      type: String,
      default: 'Yes, continue',
    },
    confirmButtonType: {
      type: String,
      default: ButtonTypes.secondary,
      validator(type) {
        return Object.values(ButtonTypes).includes(type);
      },
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  computed: {
    buttonTypes() {
      return ButtonTypes;
    },
  },

  methods: {
    handleClose(returnValue) {
      this.$emit('close', returnValue);
    },
  },
};
</script>
